import type { CardSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"

import { Card } from "@/modules/corporate/components/Card/Card"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"

export type CardPropsType = {
  section: CardSection
}

export const CardWrapper: FunctionComponent<CardPropsType> = ({ section }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  return (
    <Layout hasTopPadding hasBottomPadding>
      <Layout.Column>
        <Card
          title={section.title}
          richDescription={section.description}
          ctaLink={!!section.ctaLink.length && section.ctaLink[0]}
          ctaText={section.ctaTitle}
          theme={section.theme[0]}
          onCTAClick={() =>
            trackClickEvent("corporate_cta_card", {
              clicked_url: `/${locale}/${URL_PATHNAME}/${section.ctaLink[0].slug}`,
              visual_context: section.ctaTitle,
            })
          }
        />
      </Layout.Column>
    </Layout>
  )
}
